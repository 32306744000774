.terminal {
    width: 90vw;
    height: 90vh;
    margin-left: 5vw;
    margin-right: 5vw;
    border-radius: 10px;
    overflow: hidden;
    transform: translateY(5vh);
    font-family: cons;
}

.terminal .terminal-top{
    position: sticky;
    height: 4.5vh;
    width: 100%;
    background-color: rgb(76, 76, 76);
    text-align: left;
}

.terminal .terminal-top .cmd-label{
    float: left;
    height: 100%;
    width: 40%;
}

.terminal .terminal-top .cmd-label .icon{
    float: left;
    height: 3vh;
    width: 3vh;
    background-image: url(terminal.svg);
    background-size: cover;
    margin-left: 10px;
    border-radius: 5px;
    margin-top: .5vh;
}

.terminal .terminal-top .cmd-label .terminal-name{
    float: left;
    height: 3vh;
    margin-left: 10px;
    border-radius: 5px;
    margin-top: .5vh;
}


.terminal .terminal-top .bar-button{
    height: 100%;
    width: 3%;
    float: right;
    text-align: center;
    color: white;
}

.terminal .terminal-top .close:hover{
    background-color: rgba(139, 0, 0, 0.712);
}

.terminal .terminal-top .change-size:hover{
    background-color: rgba(219, 219, 219, 0.425);
}

.terminal .main-terminal {
    background-color: black;
    color: rgb(0, 164, 0);
    text-align: left;
    height: calc(100% - 4.5vh);
    width: 100%;
    position: sticky;
    overflow-y: auto;
    overflow-wrap: break-word;
}

.terminal .main-terminal.no-overflow{
    overflow: hidden;
}

.terminal .cmd-input {
    background-color: black;
    width: 60%;
    color: rgb(0, 164, 0);
    font-size: inherit;
    border: 0;
    height: 100%;
}

.terminal .cmd-input:focus {
    outline: none;
}

.notMinimized {
    animation-fill-mode: forwards;
    animation-duration: .2s;
    animation-name: unMinimize;
}

.minimized {
    animation-fill-mode: forwards;
    animation-duration: .3s;
    animation-name: minimize;
}

.maximized {
    animation-fill-mode: forwards;
    animation-duration: .2s;
    animation-name: maximize;
}

@keyframes minimize {
    from {
        
    }
    to {
        width: 0px;
        height: 0px;
        opacity: 0;
    }
}

@keyframes unMinimize {
    from {
        width: 0px;
        height: 0px;
        opacity: 0;
    }
    to {
        width: 90vw;
        height: 90vh;
        opacity: 1;
    }
}

@keyframes maximize {
    from {

    }
    to {
        width: 100vw;
        height: 100vh;
        margin-left: 0px;
        margin-right: 0px;
        transform: translateY(0px);
        border-radius: 0px;
    }
}

.terminal .main-terminal .nano-terminal{
    width: 100%;
    height: 100%;
}

.terminal .main-terminal .nano-top {
    width: 100%;
    height: max-content;
    text-align: center;
    background-color: bisque;
}

.terminal .main-terminal .nano-input {
    background-color: black;
    width: 100%;
    color: rgb(0, 164, 0);
    font-size: inherit;
    height: calc(100% - 4ch);
    position: sticky;
    border: none;
    outline: none;
    resize: none;
}

.terminal .main-terminal .nano-bottom {
    width: 100%;
    height: max-content;
}

.terminal .folder {
    color: lightskyblue;
}

.terminal .file {
    color: lightcoral;
}

.terminal .command-output-error {
    color: crimson;
}

.terminal .opening-new-site {
    color: yellow;
}

.terminal .opened-file {
    color: darkcyan;
}

.terminal .terminal-link {
    color: aqua;
}

.terminal .main-terminal .file.clickable-text:hover {
    color: rgb(227, 186, 186); 
    cursor: pointer;
}

.terminal .main-terminal .folder.clickable-text:hover {
    color: rgb(206, 230, 246);
    cursor: pointer;
}

.main-terminal div{
    margin-left: 1ch;
}

@font-face {
    font-family: cons;
    src: local(Consolas), url(./Consolas.woff) format('woff');
}