/* #home{
    background-image:url(background.svg);
    height: 100vh;
    width: 100vw;
    background-size: cover;
    z-index: -3;
    position: absolute;
} */

#terminal-icon {
    position: absolute;
    left: 10vw;
    top: 10vh;
    width: 8vh;
    height: 8vh;
    background-image: url(terminal.svg);
    background-size: cover;
    z-index: -1;
    cursor:pointer;
    background-color: white;
    border-radius: 10px;
}

#terminal-icon-text{
    position: absolute;
    left: 10vw;
    top: 18vh;
    z-index: -1;
    text-align: center;
    width: 8vh;
}

#delete-DB {
    position: absolute;
    left: 10vw;
    top: 22vh;
    width: 8vh;
    height: 8vh;
    background-image: url(DB_Icon.svg);
    background-size: cover;
    z-index: -1;
    cursor:pointer;
    background-color: white;
    border-radius: 10px;
}

#delete-DB-icon-text{
    position: absolute;
    left: 10vw;
    width: 8vh;
    top: 30vh;
    z-index: -1;
    text-align: center;
}

#mouse-pointer {
    position: absolute;
    left: 50vw;
    top: 50vh;
    height: 6vh;
    width: 6vh;
    background-image: url(pointer.png);
    background-size: cover;
    animation: mouse-move-to-click;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.40, 0.3, 0.15, 1);
}

@keyframes mouse-move-to-click {
    97% {
        left: calc(10vw + 3vh);
        top: 14vh;
        background-image: url(pointer.png);
        height: 6vh;
        width: 6vh;
    }

    98% {
        left: calc(10vw + 3.5vh);
        top: 13vh;
        height: 5vh;
        width: 5vh;
    }

    100% {
        left: calc(10vw + 3vh);
        top: 13vh;
    }

}